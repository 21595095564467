import { Injectable } from '@angular/core';
import { showCloseLoading } from '@shared/components/loading';

import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { DataSetFileTypes, IDataSetImageFileUploadResponse, IDataSetImageGetResponse } from '@shared/models/client-data';
import { ImageDatasetsApiService } from '@shared/services';
import { generateUUID } from '@shared/utils';
import { BehaviorSubject, Observable, catchError, combineLatest, debounceTime, distinctUntilChanged, firstValueFrom, iif, map, of, shareReplay, startWith, switchMap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ImageDatasetsService {

	constructor(private apiService: ImageDatasetsApiService) {
	}

	nameValidator: AsyncValidatorFn = (control: AbstractControl) => {
		return firstValueFrom(this.isNameUsed$(control.value).pipe(
			map(result => result ? { notAvailable: `The name "${control.value}" already taken for this client` } : null),
			catchError(() => of({ validationApiError: 'API failed checking name' }))
		));
	};

	displayNameValidator: AsyncValidatorFn = (control: AbstractControl) => {
		return firstValueFrom(this.isDisplayNameUsed$(control.value).pipe(
			map(result => result ? { notAvailable: `The display name "${control.value}" already taken for this client` } : null),
			catchError(() => of({ validationApiError: 'API failed checking display name' }))
		));
	}

	private selectedKeyBh$ = new BehaviorSubject<Observable<{ clientId: string, id: string | null }>>(of({ clientId: '', id: '' }));
	private selectedKey$ = this.selectedKeyBh$.pipe(switchMap(x$ => x$), distinctUntilChanged(), shareReplay(1));
	public setSelectedKey = (selectedKey$: Observable<{ clientId: string, id: string | null }>) => this.selectedKeyBh$.next(selectedKey$);

	private selectedVersionIdBh$ = new BehaviorSubject<Observable<string>>(of(''));
	private selectedVersionId$ = this.selectedVersionIdBh$.pipe(switchMap(x$ => x$), distinctUntilChanged(), shareReplay(1));
	public setSelectedVersionId = (selectedVerionId$: Observable<string>) => this.selectedVersionIdBh$.next(selectedVerionId$);


	public selected$ = this.selectedKey$.pipe(
		this.apiService.autoReload(),
		switchMap(key =>
			iif(() => !key.id || key.id === 'new',
				of({ clientId: key.clientId, id: generateUUID() } as IDataSetImageGetResponse),
				this.apiService.get$(key.clientId, key.id!).pipe(showCloseLoading('Loading'))
			)
		),
		shareReplay(1)
	);

	public selectedVersion$ = combineLatest({ versionId: this.selectedVersionId$, key: this.selectedKey$ }).pipe(
		switchMap(keys =>
			iif(() => this.uploadValidationResults?.version.id === keys.versionId,
				of(this.uploadValidationResults),
				this.apiService.getFileVersionValidation$(keys.key.clientId, keys.key.id!, keys.versionId).pipe(startWith(null), showCloseLoading('Loading'))
			)
		),
		shareReplay(1)
	);

	public create$ = this.apiService.create$;
	public update$ = this.apiService.update$;

	public listing$ = (clientId$: Observable<string>) => clientId$.pipe(
		switchMap(clientId => this.apiService.getList$(clientId).pipe()),
		showCloseLoading('Loading')
	);

	public uploadDataSet$ = this.apiService.uploadDataSet$;

	public downloadDataSet$ = (fileType: DataSetFileTypes) => combineLatest({ versionId: this.selectedVersionId$, key: this.selectedKey$ }).pipe(
		switchMap(keys => this.apiService.downloadDataSet$(keys.key.clientId, keys.key.id!, keys.versionId, fileType).pipe(showCloseLoading('Downloading')))
	);

	public getFileVersionValidation$ = this.apiService.getFileVersionValidation$;

	public uploadValidationResults: IDataSetImageFileUploadResponse;
	public setSelectedFileValidationResults(x: IDataSetImageFileUploadResponse) {
		this.uploadValidationResults = x;
	}

	public isNameUsed$ = (name: string) => this.selectedKey$.pipe(
		debounceTime(500),
		switchMap(key => this.apiService.isNameUsed$(key.clientId, name)));

	public isDisplayNameUsed$ = (name: string) => this.selectedKey$.pipe(
		debounceTime(500),
		switchMap(key => this.apiService.isDisplayNameUsed$(key.clientId, name, key.id ?? 'new'))
	);
}

